import React from 'react';

export default class Photos extends React.Component{


render(){
    return(
        <div>
        <h1> Photos </h1>
        <h2>Coming Soon...</h2>
        </div>
    )
}
}
